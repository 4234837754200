import { Auth0Provider } from "@auth0/auth0-react";
import { BlueprintProvider, Intent, Spinner, SpinnerSize } from "@blueprintjs/core";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { useTranslation } from "react-i18next";
import AppRouter from "./AppRouter";
import "./i18n";
import reportWebVitals from "./reportWebVitals";
import { audience, clientId, domain, redirectUri } from "./settings";

import "./styles/index.scss";

import ReactGA from "react-ga4";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://4af65d383c5f4bc4002ceb5a05b22a12@o4507865207537664.ingest.de.sentry.io/4507865209700432",
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/budget4you\.org\/api/,
    /^https:\/\/budget\.constexpr\.pl\/api/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export const App = () => {
  const onRedirectCallback = (appState) => {
    console.log("onRedirectCallback TODO", appState);
  };

  const { ready } = useTranslation("");
  ReactGA.initialize("G-Q3Q03LZBDV");
  return ready ? (
    <Suspense fallback="Loading ...">
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        redirectUri={redirectUri}
        audience={audience}
        onRedirectCallback={onRedirectCallback}
      >
        <AppRouter />
      </Auth0Provider>
    </Suspense>
  ) : (
    <Spinner size={SpinnerSize.SIZE_LARGE} intent={Intent.PRIMARY} />
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BlueprintProvider>
      <App />
    </BlueprintProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
