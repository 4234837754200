import { useTranslation } from "react-i18next";
import TwitterIcon from "../components/common/TwitterIcon";
import { facebook, instagram, x } from "../settings";
import InstagramIcon from "./common/InstagramIcon";
import FacebookIcon from "./common/FacebookIcon";
import InternalLink from "./common/InternalLink";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-gray-700 text-gray-500">
      <div className="flex flex-col md:flex-row p-12">
        <div className="md:flex-grow md:basis-1 mb-6">
          <h6>{t("app.copyrights.images")}</h6>
          <div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.freepik.com/free-vector/depressed-sad-man-thinking-financial-problems_8270994.htm#page=2&query=losing%20money&position=9&from_view=keyword&track=ais"
            >
              Image by pch.vector
            </a>{" "}
            on Freepik
          </div>
          <div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.freepik.com/free-vector/beach-sketch-background-with-palm-tree_1104563.htm#fromView=search&page=1&position=19&uuid=33c8d564-17ad-49ec-85d4-4ec05f7c4783"
            >
              Image
            </a>{" "}
            designed by Freepik
          </div>
          <div>
            <a href="https://www.freepik.com/free-vector/digital-device-mockup_4168605.htm#query=laptop%20tablet%20phone%20mockup&position=1&from_view=keyword&track=ais_hybrid&uuid=074ae925-da09-471e-80cf-298a0374079f">
              Image by rawpixel.com on Freepik
            </a>
          </div>
        </div>
        <div className="md:flex-grow md:basis-1 mb-6 hidden md:block">
          <span dangerouslySetInnerHTML={{ __html: t("app.copyrights.owner") }} />{" "}
          <button
            onClick={() => {
              throw new Error("sentry test");
            }}
          >
            {new Date().getFullYear()}
          </button>
        </div>
        <div className="md:flex-grow md:basis-1 mb-6 ">
          <div className="footer-links">
            {t("labels.links")}
            <ul className="">
              <li className="py-1">
                <InternalLink to="/pricing">{t("labels.pricing")}</InternalLink>
              </li>
              <li className="py-1">
                <InternalLink to="/roadmap">{t("labels.roadmap")}</InternalLink>
              </li>
              <li className="py-1">
                <InternalLink to="/contact">{t("labels.contact_us")}</InternalLink>
              </li>
              <li className="py-1">
                <InternalLink to="/privacy-policy">{t("labels.privacy_policy")}</InternalLink>
              </li>
              <li className="py-1">
                <InternalLink to="/changelog">{t("labels.changelog")}</InternalLink>
              </li>
            </ul>
          </div>
          <div className="social-links mt-5">
            <a href={x} target="_blank" rel="noopener noreferrer">
              <TwitterIcon />
            </a>
            <a href={instagram} target="_blank" rel="noopener noreferrer">
              <InstagramIcon />
            </a>
            <a href={facebook} target="_blank" rel="noopener noreferrer">
              <FacebookIcon />
            </a>
          </div>
        </div>
        <div className="md:flex-grow md:basis-1 mb-6 block md:hidden">
          <span dangerouslySetInnerHTML={{ __html: t("app.copyrights.owner") }} />{" "}
          <button
            onClick={() => {
              throw new Error("sentry test");
            }}
          >
            {new Date().getFullYear()}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Footer;
