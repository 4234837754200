import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Spinner, SpinnerSize } from "@blueprintjs/core";
import React from "react";

const ProtectedRoute = ({
  component,
  ...rest
}: {
  component: React.ComponentType<any>;
  rest: any;
}) => {
  return withAuthenticationRequired(component, {
    onRedirecting: () => <Spinner size={SpinnerSize.STANDARD} />,
  })(rest);
};

export default ProtectedRoute;
