import { useQuery } from "@apollo/client";
import { useContext } from "react";
import { BUDGET } from "../../graphql/queries/budget";
import { BudgetContext } from "../WithBudgetContext";
import WithLoadingSpinner from "../common/WithLoadingSpinner";
import Budget from "./Budget";

export default function Dashboard() {
  const currentBudget = useContext(BudgetContext);

  return (
    <div>{currentBudget && currentBudget.id && <BudgetLoader budgetId={currentBudget.id} />}</div>
  );
}

const BudgetLoader = ({ budgetId }: { budgetId: number }) => {
  const { data, loading, error } = useQuery(BUDGET, { variables: { budgetId } });

  return (
    <WithLoadingSpinner isLoading={loading}>
      {error ? <div>{error.message}</div> : <Budget budget={data?.budgets_by_pk} />}
    </WithLoadingSpinner>
  );
};
