import _ from "lodash";
import { Button, Collapse, Divider, Icon, Intent, NonIdealState, Tag } from "@blueprintjs/core";
import { useTranslation } from "react-i18next";
import { TBudgetSection } from "../../utils/budget_samples";
import { IconNames } from "@blueprintjs/icons";
import { BlueprintIcons_16Id } from "@blueprintjs/icons/lib/esm/generated/16px/blueprint-icons-16";
import { useState } from "react";

export const ICON_MAP: Record<string, BlueprintIcons_16Id> = {
  housing: IconNames.HOME,
  bills: IconNames.CREDIT_CARD,
  groceries: IconNames.GLASS,
  investments: IconNames.DOLLAR,
  childcare: IconNames.SOCCER_BALL,
  regular: IconNames.REFRESH,
  miscellaneous: IconNames.RANDOM,
  savings: IconNames.DOLLAR,
};

export const BudgetItem = ({
  removeFromSection,
  section,
}: {
  removeFromSection: (name: string) => void;
  section: TBudgetSection;
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <div>
        <div
          className="p-1 flex flex-row cursor-pointer hover:bg-gray-300"
          onClick={() => setIsOpen((open) => !open)}
        >
          <div className="flex-grow ">
            <div>
              <Icon className="mr-3" icon={ICON_MAP[section.section_name]} />{" "}
              {t(`labels.${section.section_name}`)}
            </div>
          </div>
          <Icon
            className={`transition-all ${isOpen ? "rotate-180" : "rotate-0"}`}
            icon={IconNames.CHEVRON_DOWN}
          />
        </div>
        <div className="my-1">
          <Collapse isOpen={isOpen}>
            {/* <ul className="bp5-list bp5-list-unstyled">
              
            </ul> */}
            {_.map(section.entries, (entry) => {
              return (
                <Tag
                  className="mr-1 mb-1"
                  key={entry.name}
                  interactive
                  minimal
                  large
                  //@ts-ignore
                  removable
                  onRemove={() => removeFromSection(entry.name)}
                >
                  {t(`labels.${entry.name}`)}
                </Tag>
              );
            })}
          </Collapse>
        </div>
      </div>
    </div>
  );
};

export const EmptyBudget = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();
  const label = t("labels.empty_budget");
  return (
    <NonIdealState
      icon={IconNames.CHART}
      title={<div>{label}</div>}
      description={<div>{t("messages.create_empty_budget")}</div>}
      action={
        <Button
          icon={IconNames.MAP_CREATE}
          onClick={onClick}
          intent={Intent.SUCCESS}
          aria-roledescription="create-budget"
        >
          {t("actions.create_budget")}
        </Button>
      }
    ></NonIdealState>
  );
};
