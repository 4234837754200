import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useAuth0 } from "@auth0/auth0-react";
import { Spinner, SpinnerSize } from "@blueprintjs/core";
import _ from "lodash";
import { ReactNode, useCallback, useEffect } from "react";
import { audience, graphqlUri } from "../settings";
import { useTokenStore } from "../stores/useTokenStore";
import PublicRoutes from "./PublicRoutes";
import WithUserContext from "./WithUserContext";
import React from "react";

const httpLink = new HttpLink({
  uri: graphqlUri,
});

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

const getApolloClient = (token: string | null) => {
  if (apolloClient && !_.isEmpty(token)) return apolloClient;
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
    },
  });
  return apolloClient;
};

const WithToken = ({ children }: { children: ReactNode }) => {
  const { getAccessTokenSilently, isLoading, user } = useAuth0();
  const { token, setToken } = useTokenStore((state) => ({
    ...state,
  }));

  const getToken = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently({
        audience,
      });
      setToken(token);
    } catch (err) {
      console.log(err);
    }
  }, [getAccessTokenSilently, setToken]);

  useEffect(() => {
    getToken();
  }, []);

  return (
    <React.Fragment>
      {_.isEmpty(token) && isLoading ? (
        <Spinner size={SpinnerSize.LARGE} />
      ) : _.isEmpty(token) ? (
        <PublicRoutes />
      ) : (
        <ApolloProvider client={getApolloClient(token)}>
          <WithUserContext authUser={user}>{children}</WithUserContext>
        </ApolloProvider>
      )}
    </React.Fragment>
  );
};

export default WithToken;
